<template>
  <a-modal
    width="620px"
    wrap-class-name="language-modal"
    :dialog-style="{ display: 'none' }"
    :body-style="{ height: '610px', display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center' }"
    :footer="null"
    :visible="visible"
    @cancel="closeModal">
    <div>
      <h1 class="mb-24 txt-32 txt-font-din-bold">{{ $t('components.titles.languageSelection') }}</h1>
      <a-card :body-style="{ paddingTop: '24px', paddingBottom: '24px'}">
        <h1 class="txt-14 txt-font-din-bold">{{ $t('components.titles.formLanguages') }}</h1>
        <a-checkbox-group v-model="checkedList" class="language-list">
          <div v-for="item in languages" :key="item.value">
            <a-checkbox
              :value="item.value"
              :disabled="item.disabled || isSupervisor"
              class="txt-16">
                {{ item.label }}
              </a-checkbox>
          </div>
        </a-checkbox-group>
      </a-card>
    </div>
    <div>
      <a-card>
        <h1 class="txt-14 txt-font-din-bold mb-16">{{ $t('components.titles.defaultLanguage') }}</h1>
        <a-select
          v-model="defaultLanguage"
          :get-popup-container="(triggerNode) => triggerNode.parentNode"
          class="w50">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option v-for="(lang, index) in selectedLanguages" :key="index" :disabled="isSupervisor" :value="lang.value">
            {{ lang.label }}
          </a-select-option>
        </a-select>
      </a-card>
    </div>
    <a-alert class="language-alert" type="info" show-icon>
       <template #icon>
        <a-icon type="info-circle" :style="{ fontSize: '25px', marginRight: '10px' }" theme="filled"/>
       </template>
      <template #description>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="txt-font-din-regular txt-16" v-html="$t('components.description.onceYouCreateTheFormYouCanUseTranslation')"></span>
      </template>
    </a-alert>
    <div class="footer-btn">
      <a-button
        type="primary"
        size="large"
        :disabled="isSupervisor"
        @click="compeleteModal">
        {{ $t('components.titles.createInFormBuilder') }}
      </a-button>
    </div>
  </a-modal>
</template>
<script>
import langs from '@/util/languages';
import { mapState } from 'vuex';

export default {
  name: 'LanguageModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: undefined
    },
    existingLanguages: {
      type: Array,
      default: () => ['English']
    },
    closeLanguageModal: {
      type: Function,
      default: () => {}
    },
    onComplete: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      checkedList: ['English'],
      defaultLanguage: 'English'
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    languages() {
      return langs.call(this).filter((lang) => (this.existingLanguages.includes(lang.value)));
    },
    selectedLanguages() {
      return (this.languages.filter(x => this.checkedList.includes(x.value)));
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.defaultLanguage = this.getDefaultLanguage();
      }
    },
    checkedList(newP, oldP) {
      if (newP.length < oldP.length) {
        const diffLang = oldP.find((lang) => (!newP.includes(lang)));
        if (!diffLang || this.defaultLanguage === diffLang) {
          this.defaultLanguage = 'English';
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.closeLanguageModal();
    },
    compeleteModal() {
      this.onChange(this.defaultLanguage);
      this.closeLanguageModal();
      this.onComplete(this.checkedList, this.defaultLanguage);
    },
    getDefaultLanguage() {
      let defLang = this.languages.find((lang) => (lang.value === 'English')).label;
      const val = this.value;
      if (val < this.existingLanguages.length) {
        const langVal = this.existingLanguages[val];
        const defTemp = this.languages.find((lang) => (lang.value === langVal)).label;
        if (defTemp) defLang = defTemp;
      }
      return defLang;
    },
    onChange(langValue) {
      const langIndex = this.existingLanguages.findIndex((lang) => (lang === langValue));
      this.$emit('languageChange', langIndex);
    }
  }
};
</script>
<style scoped>
.ant-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer-btn {
  text-align: right;
}
.footer-btn button {
  width: 235px;
  height: 48px;
}
.language-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.language-list > div {
  padding: 10px 10px 10px 0px;
}
.language-alert {
  height: fit-content;
}
.language-alert > .ant-alert-message {
  padding-left: 10px;
}
</style>
